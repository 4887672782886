.App {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.App-header {
  height: 15vmin;
  background-color: blueviolet;
  /* min-height: 100vh; */
  order: 1;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-logo {
  order: 1;
  /* width: 50px; */
}

.logo {
  width: 100px;
  height: 100px;
}

.App-title {
  order: 2;
}

.App-nav {
  order: 3;
  padding-left: 5em;
}

.nav {
  font-weight: bold;
}

.App-main {
  background-color: orange;
  /* min-height: 100vh; */
  /* display: flex; */
  /* flex-direction: column; */
  order: 2;
  flex-grow: 3;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

h1 {
  font-size: 1.5em;
  line-height: 70%;
}

p {
  font-size: 0.8em;
}

.App-footer {
  font-size: smaller;
  align-items: center;
  justify-content: center;
  order: 10;
  display: flex;
}